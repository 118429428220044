import {Controller} from "stimulus";
import {withPopUp, withPopUpTargets} from "mixins/withPopUp";
import {withToggles} from "mixins/withToggles";

export default class extends withToggles(withPopUp(Controller)) {
  static targets = [...withPopUpTargets]

  closeToggle() {
    super.closeToggle();
    this.closeDropDown();
    this.updateUI();
  }

  openToggle() {
    super.openToggle();
    this.openDropDown();
    this.updateUI();
  }

  updateUI() {
    if (this.isOpen()) {
      this.getDropDownTrigger().innerText = 'Show Less '
    } else {
      this.getDropDownTrigger().innerText = 'Show All '
    }
  }

  getDropDownHideClass() {
    return 'listings-checkbox-group__container--closed';
  }
}
