import { Controller } from "stimulus";
import {withSubscriber} from "mixins/withSubscriber";

export default class extends withSubscriber(Controller) {

    connect() {
        super.connect();
        this.boundNotifyHelper = this.notifyHelper.bind(this);

        this.element.querySelectorAll('input').forEach(el => {
            el.addEventListener('change', this.boundNotifyHelper);
        })
    }

    clear() {
        this.element.querySelectorAll('input').forEach(el => {
            if (el.hasAttribute('min')) {
                el.value = el.min;
                el.dispatchEvent(new Event('change'));
            } else if (el.type === 'radio' || el.type === 'checkbox') {
                el.checked = false;
                el.dispatchEvent(new Event('change'));
            } else {
                el.value = '';
                el.dispatchEvent(new Event('change'));
            }
        })
    }

    notifyHelper(e) {
        this.notify(e.target.name, e.target.value, e.target.type, e.target.checked, e);
    }

    dispatchChangeEventWithoutNotification(node) {
        node.removeEventListener('change', this.boundNotifyHelper)
        // emit this in case the input field has validation.
        node.dispatchEvent(new Event('change'));
        node.addEventListener('change', this.boundNotifyHelper)
    }

    onNotification(event) {
        event.preventDefault();
        const [inputName, inputValue, inputType, isInputChecked] = event.detail.args;

        const node = inputName.includes('[') ?
            Array.from(this.element.querySelectorAll(`input[name="${inputName}"]`)).filter(node => node.value === inputValue)[0] :
            this.element.querySelector(`input[name=${inputName}]`);
        if (!!node) {
            if (inputType === 'radio' || inputType === 'checkbox') {
                node.checked = isInputChecked;
                this.dispatchChangeEventWithoutNotification(node);
            } else {
                node.value = inputValue;
                this.dispatchChangeEventWithoutNotification(node);
            }
        }
    }
}
