import { Controller } from "stimulus";
import {withSubscriber} from "mixins/withSubscriber";

export default class extends withSubscriber(Controller) {
  static targets = ["arrivalContainer", "arrivalInput", "departureContainer", "departureInput"];
  connect() {
    super.connect();
    this.selectedDates = [];
    // set initial dates if they exist
    if (this.element.hasAttribute("data-initial-checkin-date")) {
      this.selectedDates.push(new Date(this.element.dataset.initialCheckinDate));
    }
    if (this.element.hasAttribute("data-initial-checkout-date")) {
      this.selectedDates.push(new Date(this.element.dataset.initialCheckoutDate));
    }
  }
  onNotification(event) {
    if (event.detail.channel === this.getChannel()) {
      const [selectedDates, dateStr, instance] = event.detail.args;
      this.selectedDates = selectedDates;
      this.updateUI();
    }
  }

  clearArrival() {
    this.selectedDates[0] = null;
    this.updateUI();
    this.notify(this.selectedDates, null, null);
  }

  clearDeparture() {
    this.selectedDates[1] = null;
    this.updateUI();
    this.notify(this.selectedDates, null, null);
  }

  updateUI() {
    this.updateDateValue();
    this.updateCSSClasses();
  }

  updateDateValue(selectedDates) {
    this.arrivalInputTarget.value = this.formatDate(this.selectedDates.length > 0 ? this.selectedDates[0] : null);
    this.departureInputTarget.value = this.formatDate(this.selectedDates.length > 1 ? this.selectedDates[1] : null);
  }

  updateCSSClasses() {
    this.selectedDates.length > 0 && !!this.selectedDates[0] ?
        this.arrivalContainerTarget.classList.add(this.getWithDateClass()) :
        this.arrivalContainerTarget.classList.remove(this.getWithDateClass());
    this.selectedDates.length > 1 && !!this.selectedDates[1]?
        this.departureContainerTarget.classList.add(this.getWithDateClass()) :
        this.departureContainerTarget.classList.remove(this.getWithDateClass());
  }

  getWithDateClass() {
    return 'calendar-input-fields__field--with-date';
  }

  formatDate(date) {
    if (!date) {
      return null;
    }
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear().toString().substring(2);
  }

}
