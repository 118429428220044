import {Controller} from "stimulus"
import {submitForm} from "init/ajax"

export default class extends Controller {

  static targets = ["modal", "form", "modalButton"]

  connect() {
    console.log('Connecting')
    this.modalTarget.classList.add('is-inactive');

    document.addEventListener('fetch-rates', (event) => {
      console.log('fetch-rates event executed in get_a_quote_form')
      this.activateButton(event)
    });
  }

  openModal(event) {
    event.preventDefault();
    const modal = this.modalTarget;
    modal.classList.remove('is-inactive');
    modal.classList.add('is-active');
    console.log('open Modal getAQuoteForm');
  }

  thankYou(event) {
    event.preventDefault();
    
    // replace form with success message
    const form = this.formTarget;
    const successMessage = document.createElement('p');
    successMessage.classList.add('success-message');
    successMessage.innerHTML = "Thank you for your request. We will be in touch shortly.";

    // hide from .GetAQuoteForm__container__header p.GetAQuoteForm__container__header__description 
    const formHeader = document.querySelector('.GetAQuoteForm__container__header p.GetAQuoteForm__container__header__description');
    formHeader.style.display = 'none';
    
    form.parentNode.replaceChild(successMessage, form);

    // wait 3 seconds before adding is-inactive class
    setTimeout(() => {
      this.modalTarget.classList.remove("is-active");
      this.modalTarget.classList.add('is-inactive');
    }, 3000);
    console.log("close Modal getAQuoteForm");
  }

  closeModal(event) {
    event.preventDefault();
    this.modalTarget.classList.remove("is-active");
    this.modalTarget.classList.add('is-inactive');
    console.log("close Modal getAQuoteForm");
  }

  activateButton(event) {
    event.preventDefault();
    console.log('activateButton executed');
    this.modalButtonTarget.removeAttribute('disabled');
  }
}