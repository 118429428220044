import { Controller } from "stimulus";
import { submitForm, loadRequestHtmlBy  } from "init/ajax";
import { formParams } from "init/forms";

export default class extends Controller {
  connect() {
    this.formPassword = this.element.querySelector('.form__password');
    this.mask = this.element.querySelector(".btn--mask");
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }

  async changeEmail(event) {
    event.preventDefault();

    let responseHTML = await loadRequestHtmlBy(event.target.href)

    this.element.replaceWith(responseHTML.querySelector('.js-AddEmail__modal'))
  }

  async create(event) {
    event.preventDefault();

    const form = event.target.parentElement;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);

      let pendingConfirmationModalElement = responseHTML.querySelector('.js-PendingConfirmation__modal')
      let createAccountModalElement = responseHTML.querySelector('.js-CreateAccountModal__section')

      if (pendingConfirmationModalElement) {
        this.element.replaceWith(pendingConfirmationModalElement)
      } else if (createAccountModalElement) {
        this.element.replaceWith(createAccountModalElement)
      }
    }
  }

  togglePassword(event) {
    event.preventDefault()

    if (this.formPassword.type === 'password') {
      this.formPassword.type = 'text'
      this.mask.classList.add('is-unmasked');
    } else {
      this.formPassword.type = 'password'
      this.mask.classList.remove('is-unmasked');
    }
  }
}
