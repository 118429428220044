import Flatpickr from "stimulus-flatpickr";
import {withSubscriber} from "mixins/withSubscriber";
import {withToggleDatePickerOutsideListeners} from "mixins/withToggleDatePickerOutsideListeners";

export default class extends withSubscriber(withToggleDatePickerOutsideListeners(Flatpickr)) {
  static targets = ["arrivalText", "departureText"];
  initialize() {
    super.initialize();
    let iconNextString = document.querySelector('.js-BookingCalendar__next-month-arrow img').outerHTML

    let config = {
      minDate: document.getElementById('reservation_lead_time').value,
      nextArrow: iconNextString,
      prevArrow: iconNextString,
      dateFormat: "m/d/y",
      position: "auto",
      mode: "range",
      theme: "light",
      clickOpens: false,
      closeOnSelect: false,
      appendTo: this.element,
      locale: {
        firstDayOfWeek: 7
      },
    }

    window.isLoaded = false

    this.config = config

    this.element.addEventListener('open-toggle', this.openDatePicker.bind(this))
    this.setPromoBannerTop()
    window.onresize = this.promoResize.bind(this)
    window.onload = this.pageLoad.bind(this)
  }

  pageLoad() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let dates = [params.checkin_date, params.checkout_date].filter(x => x !== null)
    if (dates.length) {
      let [checkin, checkout] = dates
      dates = dates.map(d => this.fp.parseDate(d, 'Y-m-d'))
      this.fp.setDate(dates, true)
      document.querySelectorAll('[name="check_out_date_hidden"]').forEach(el => el.value = checkout)
      document.querySelectorAll('[name="check_in_date_hidden"]').forEach(el => el.value = checkin)
    }
    window.isLoaded = true
  }

  connect() {
    super.connect();
    if (this.fp && this.fp.calendarContainer) {
      let wrapper = document.createElement('div')
      wrapper.classList.add('Calendar__wrapper')
      wrapper.classList.add('Calendar__wrapper--gray')
      wrapper.appendChild(this.fp.calendarContainer)
      document.body.appendChild(wrapper)

      const guideContainerNode = this.element.querySelector('.js-listings-date-picker__guide-container-wrapper').children[0]
      const guideContainerNodeClone = guideContainerNode.cloneNode(true)
      this.fp.calendarContainer.appendChild(guideContainerNodeClone)
      this.addYearSelector()
    }
    const initialDates = [];
    // set initial dates if they exist
    if (this.element.hasAttribute("data-initial-checkin-date")) {
      initialDates.push(new Date(this.element.dataset.initialCheckinDate));
    }
    if (this.element.hasAttribute("data-initial-checkout-date")) {
      initialDates.push(new Date(this.element.dataset.initialCheckoutDate));
    }
    this.fp.setDate(initialDates)

    // these are queried by an external form.
    this.hiddenCheckinInputNode = this.element.querySelector('.js-checkin-date-hidden');
    this.hiddenCheckoutInputNode = this.element.querySelector('.js-checkout-date-hidden');
  }

  promoResize() {
    if (this.resizeTimer) clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(this.setPromoBannerTop, 100)
  }

  setPromoBannerTop() {
    let promoBanner   = document.querySelector('.ListingDetail__promo-banner')
    if (!promoBanner) return
    let desktopHeader = document.querySelector('.header')
    let mobileHeader  = document.querySelector('.mobile-header')
    let header        = (mobileHeader.offsetHeight > 0) ? mobileHeader : desktopHeader
    let offset        = promoBanner.offsetHeight + header.offsetHeight - 1
    let mainContainer = document.querySelector('main')
    let subNavWrap    = document.querySelector('.listings-date-picker-sub-nav-wrapper--with-promo-banner')
    mainContainer.style.paddingTop = `${offset}px`
    subNavWrap.style.top = `${offset}px`
  }

  addYearSelector() {
    if (this.fp.calendarContainer.querySelector('.year-dropdown')) return;
    let currYear =  this.fp.currentYear || new Date().getFullYear();
    let select = document.createElement('select');
    select.classList.add('year-dropdown');

    for (let i = currYear; i <= currYear + 2; i++) {
      let option = document.createElement('option');
      option.value = i;
      option.innerHTML = i;
      select.appendChild(option);
    }
    this.fp.calendarContainer.querySelector('.flatpickr-current-month').append(select);
    const flatpickrYearElement = this.fp.currentYearElement;
    select.addEventListener('change', (event) => {
      flatpickrYearElement.value = event.target['value'];
      this.fp.currentYear = parseInt(event.target['value']);
      this.fp.redraw();
    });
  }

  updateHiddenInputFields(selectedDates) {
    this.hiddenCheckinInputNode.value = selectedDates.length > 0 ? this.formatDate(selectedDates[0]) : '';
    this.hiddenCheckoutInputNode.value = selectedDates.length > 1 ? this.formatDate(selectedDates[1]) : '';
  }

  formatDate(dateString) {
    var d = new Date(dateString),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  closeDatePicker() {
    this.fp.close();
  }

  openDatePicker() {
    this.fp.open(null, this.element);
  }

  closeToggle() {
    super.closeToggle();
    this.closeDatePicker();
  }
  openToggle() {
    super.openToggle();
    this.openDatePicker();
  }

  onOutsideClick(event) {
    if (!(this.element.contains(event.target) || this.fp.calendarContainer.contains(event.target))) {
      this.closeToggle();
    }
  }

  updateUI(selectedDates) {
    this.updateDateText(selectedDates);
    this.updateCSSClasses(selectedDates);
  }

  updateDateText(selectedDates) {
    this.arrivalTextTarget.innerHTML = this.formatDateUserFriendly(selectedDates.length > 0 ? selectedDates[0] : null);
    this.departureTextTarget.innerHTML = this.formatDateUserFriendly(selectedDates.length > 1 ? selectedDates[1] : null);
  }

  updateCSSClasses(selectedDates) {
    const helper = (el, hasDate) => {
      if (el.hasAttribute('data-has-date-class')) {
        if (hasDate) {
          el.classList.add(el.dataset.hasDateClass)
        } else {
          el.classList.remove(el.dataset.hasDateClass)
        }
      }
      if (el.hasAttribute('data-has-no-date-class')) {
        if (!hasDate) {
          el.classList.add(el.dataset.hasNoDateClass)
        } else {
          el.classList.remove(el.dataset.hasNoDateClass)
        }
      }

    }
    this.element.querySelectorAll('[data-triggered-by="arrival"]')
        .forEach(el => helper(el, selectedDates.length > 0))
    this.element.querySelectorAll('[data-triggered-by="departure"]')
        .forEach(el => helper(el, selectedDates.length > 1))
  }

  change(selectedDates, dateStr, instance) {
    this.notify(selectedDates, dateStr, instance);
    this.updateUI(selectedDates);
    this.updateHiddenInputFields(selectedDates);
    if (selectedDates.length > 1 && window.isLoaded) {
      this.submit();
    }
  }

  yearChange(selectedDates, dateStr, instance) {
    this.fp.calendarContainer.querySelector('.year-dropdown').remove();
    this.addYearSelector();
  }

  onNotification(event) {
    if (event.detail.channel === this.getChannel()) {
      const [selectedDates, dateStr, instance] = event.detail.args;
      if (this.fp !== instance) {
        this.fp.setDate(selectedDates);
        this.updateUI(selectedDates);
        this.updateHiddenInputFields(selectedDates);
      }
    }
  }

  formatDateUserFriendly(dateString) {
    if (!dateString) return '';
    let date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { year: '2-digit', day: '2-digit', month: '2-digit' }).format(date)

  }

  submit() {
    const event = new CustomEvent("apply-filter", {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  openModal() {
    // if (this.isDesktop()) {
    //   const openDesktopCalendarEvent = new CustomEvent("open-popup-calendar", { bubbles: true });
    //   this.element.dispatchEvent(openDesktopCalendarEvent);
    // } else {
      const openMobileCalendarEvent = new CustomEvent("open-mobile-calendar", { bubbles: true });
      this.element.dispatchEvent(openMobileCalendarEvent);
    // }
  }
}
