import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  connect() {
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }

  async recoverPassword(event) {
    event.preventDefault();

    const form = event.currentTarget
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);

      let pendingPasswordResetElement = responseHTML.querySelector('.PendingPasswordReset__modal');
      let forgotPasswordModalElement = responseHTML.querySelector('.ForgotPassword__modal');

      if (pendingPasswordResetElement) {
        this.element.replaceWith(pendingPasswordResetElement)
      } else if (forgotPasswordModalElement) {
        this.element.replaceWith(forgotPasswordModalElement)
      }
    }
  }
}
