import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  connect() {
    this.formPassword = this.element.querySelector('.form__password');
    this.mask = this.element.querySelector(".btn--mask");
  }

  togglePassword(event) {
    event.preventDefault()

    if (this.formPassword.type === 'password') {
      this.formPassword.type = 'text'
      this.mask.classList.add('is-unmasked');
    } else {
      this.formPassword.type = 'password'
      this.mask.classList.remove('is-unmasked');
    }
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }

  async resetPassword(event) {
    event.preventDefault();

    const form = event.currentTarget
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);
      let successModalElement = responseHTML.querySelector('.Success__modal')

      if (successModalElement) {

        this.element.replaceWith(successModalElement)
        window.history.pushState('/', '/', '/')
      }
    }
  }
}
