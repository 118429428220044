import {withInputField, withInputFieldTargets} from "mixins/withInputField";

export const withInputStepperFieldTargets = ["incrementor", "decrementor", ...withInputFieldTargets]

export const withInputStepperField = controller => class extends withInputField(controller) {
    static targets = withInputStepperFieldTargets
    connect() {
        super.connect();
        this.minValue = false;
        this.maxValue = false;
        if (this.getFieldValueTarget().hasAttribute('min')) {
            this.minValue = parseInt(this.fieldValueTarget.getAttribute('min'));
        }
        if (this.getFieldValueTarget().hasAttribute('max')) {
            this.maxValue = parseInt(this.fieldValueTarget.getAttribute('max'));
        }
        this.getFieldValueTarget().addEventListener('change', this.validate.bind(this))
    }

    isMaxValueSet() {
        return this.maxValue !== false;
    }

    isMinValueSet() {
        return this.minValue !== false;
    }

    validate() {
        const currentValue = parseInt(this.getInputValue());
        this.checkAndSetDisablingButtons(currentValue);
        if (this.isMaxValueSet() && currentValue > this.maxValue)
            this.setInputValue((this.maxValue).toString());
        if (this.isMinValueSet() && currentValue < this.minValue)
            this.setInputValue((this.minValue).toString());
    }

    checkAndSetDisablingButtons(i) {
        if (this.isMaxValueSet()) {
            this.incrementorTarget.disabled = parseInt(i) >= this.maxValue;
        }
        if (this.isMinValueSet()) {
            this.decrementorTarget.disabled = parseInt(i) <= this.minValue;
        }
    }

    stepUp() {
        const currentValue = parseInt(this.getInputValue());
        if ((this.isMaxValueSet() && currentValue < this.maxValue) || !this.isMaxValueSet()) {
            this.setInputValue((currentValue + 1).toString());
        }
    }

    stepDown() {
        const currentValue = parseInt(this.getInputValue());
        if ((this.isMinValueSet() && currentValue > this.minValue) || !this.isMinValueSet())
            this.setInputValue((currentValue - 1).toString());
    }

    setInputValue(v) {
        super.setInputValue(v);
        this.checkAndSetDisablingButtons(v);
    }

}
