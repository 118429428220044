import { Controller } from "stimulus";
import { postJson } from "init/ajax";

export default class extends Controller {

  submit(event) {
    event.preventDefault();

    if (this.element.querySelector('input[type="checkbox"]').checked) {
      postJson(
        '/subscriptions', {
          email: this.element.querySelector('input[type="email"]').value,
          subscribed: true
        },
        this.responseCallback.bind(this))
    }
  }

  responseCallback(response) {
    this.element.querySelector('label').setAttribute('style', 'display: block;')
    this.element.querySelector('.js-newsletter-form__submit').setAttribute('style', 'top: 69%;')

    const outerModal = document.querySelector(".newsletter-modal");
    outerModal.classList.add('fadeOut');
  }

}
