import { Controller } from "stimulus";
import { submitForm, loadRequestHtmlBy } from "init/ajax";

export default class extends Controller {
  connect() {
    let createAccountModalElement = document.querySelector('.js-CreateAccountModal__section')

    if (createAccountModalElement) {
      createAccountModalElement.classList.remove('CreateAccountModal__reveal')
    }

    this.formPassword = this.element.querySelector('.form__password');
    this.mask = this.element.querySelector(".btn--mask");
  }

  async changeEmail(event) {
    event.preventDefault();

    let responseHTML = await loadRequestHtmlBy(event.target.href)

    this.element.replaceWith(responseHTML.querySelector('.js-AddEmail__modal'))
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }

  async forgotPasswordModal(event) {
    event.preventDefault()

    let responseHTML = await loadRequestHtmlBy(event.target.href)

    this.element.replaceWith(responseHTML.querySelector('.ForgotPassword__modal'))
  }


  togglePassword(event) {
    event.preventDefault()

    if (this.formPassword.type === 'password') {
      this.formPassword.type = 'text'
      this.mask.classList.add('is-unmasked');
    } else {
      this.formPassword.type = 'password'
      this.mask.classList.remove('is-unmasked');
    }
  }

  async signIn(event) {
    event.preventDefault();

    const form = event.currentTarget
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let responseHTML = await submitForm(form);

      if (responseHTML) {
        let signInModalElement = responseHTML.querySelector('.SignIn__modal')

        if (signInModalElement) {
          this.element.replaceWith(signInModalElement)
        }
      }
    }
  }
}
