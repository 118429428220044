import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }
}
