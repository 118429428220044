import { Controller } from "stimulus";
import { submitForm, loadRequestHtmlBy } from "init/ajax";

export default class extends Controller {
  connect() {
    this.menu = this.element.querySelector("nav");
    this.hamburger = this.element.querySelector(".hamburger");
    this.menus = this.element.querySelectorAll(".has-submenu")
  }

  closeMenu() {
    this.element.classList.remove('open');
    if (this.element.querySelector(".has-submenu.open")) {
      this.element.querySelector(".has-submenu.open").classList.remove('open');
    }
    this.menu.setAttribute('aria-hidden', 'true');
    this.hamburger.setAttribute('aria-expanded', 'false');
    document.body.style.overflow = 'auto';
  }

  openMenu() {
    this.element.classList.add('open');
    this.menu.setAttribute('aria-hidden', 'false');
    this.hamburger.setAttribute('aria-expanded', 'true');
    document.body.style.overflow = 'hidden';
  }

  toggle() {
    if (this.element.classList.contains('open')) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  closeSubmenu(e) {
    if (e) e.currentTarget.classList.remove('open');
    else {
      e.target.closest('.has-submenu').classList.remove('open');
    }
  }

  openSubmenu(e) {
    if (e.target.parentNode == e.currentTarget) e.preventDefault();
    e.currentTarget.classList.add('open');
  }

  toggleSubmenu(e) {
    e.preventDefault();
    if (e.target.parentNode == e.currentTarget) e.preventDefault();
    e.currentTarget.classList.toggle('open');
  }

  backToMenu(e) {
    e.stopPropagation();
    this.menus.forEach(menu => menu.classList.remove('open'));
  }

  async showAddEmailModal(event) {
    event.preventDefault()

    let responseHTML = await loadRequestHtmlBy(event.target.href)
    let addEmailModalElement = responseHTML.querySelector('.AddEmail__modal')

    document.querySelector('body').insertAdjacentElement('afterbegin', addEmailModalElement);
  }
}
