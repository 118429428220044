import { Controller } from "stimulus";
import { postJson } from "init/ajax";


export default class extends Controller {
  static targets = ["container", "form", "submitButton"]

  responseCallback(response) {
    if (response.detail[0].status === 200) {
      const form = this.formTarget;
      const thanksMessage = document.createElement('p');
      thanksMessage.classList.add('signup-message');
      thanksMessage.textContent = 'Thank you for signing up!';
      form.style.display = 'none';
      form.insertAdjacentElement('afterend', thanksMessage);
    } else {
      let errorMessage = this.containerTarget.querySelector('.error-message');
      if (!errorMessage) {
        errorMessage = document.createElement('p');
        errorMessage.classList.add('error-message');
        this.submitButtonTarget.insertAdjacentElement('afterend', errorMessage);
      }
      errorMessage.textContent = 'There was an error signing up. Please try again.';
    }
  }
}
    