import "./index.css";
import "../config/polyfills";
import "components";

// Rails stuff
import Turbolinks from "turbolinks";
Turbolinks.start();

import Rails from "@rails/ujs";
Rails.start();
