import { Controller } from "stimulus";
import { withToggles } from "mixins/withToggles";
import { withSubscriber } from "mixins/withSubscriber";

export default class extends withSubscriber(withToggles(Controller)) {
    onNotification(event) {
        if (event.detail.channel === this.getChannel()) {
            this.toggle();
        }
    }
    onClickHandler() {
        this.toggle();
        this.notify();
        let gridTop
        if (this.isOpen()) {
            this.prevPos = window.scrollY
            console.log('isOpen', this.prevPos, window.scrollY)
            gridTop = document.querySelector('.Listings__grid-wrapper').offsetTop
        } else {
            console.log('not isOpen', this.prevPos, window.scrollY)
            gridTop = this.prevPos
        }
        if (window.outerWidth < 1200) {
            window.scrollTo(0, gridTop)
        }
    }
}
