import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  static targets = ['form']
  connect() {
    this.formPassword = this.element.querySelector('.form__password');
    this.mask = this.element.querySelector(".btn--mask");
  }

  closeModal(event) {
    event.preventDefault();

    this.element.remove()
  }

  validate() {
    const requiredFields = this.formTarget.querySelectorAll("[required]");
    let valid = true;
    this.invalid_fields = [];
    requiredFields.forEach(field => {
      const parent = field.parentNode.parentNode;
      const errors = parent.querySelectorAll(".Modal__error-description");
      const error_msg = errors.length > 0 ? errors[errors.length - 1] : null;

      if (field.checkValidity()) {
        // Valid Field
        parent.classList.remove("error");
        if (error_msg) error_msg.remove();
      } else {
        // Invalid Field
        const child = error_msg ? error_msg : document.createElement("span");
        const msg = field.validationMessage;
        this.invalid_fields.push(field);
        if (!parent.querySelector(".Modal__error-description")) {
          child.classList.add("Modal__error-description");
          child.innerText = msg;
          parent.classList.add("error");
          parent.prepend(child);
        } else {
          child.innerText = msg;
        }
        valid = false;
      }
    });
    return valid;
  }

  async submit(event) {
    event.preventDefault();

    const form = this.formTarget
    // Consider everything valid if browser validations aren't supported
    if (this.validate()) {
      let responseHTML = await submitForm(form);

      let createAccountModalElement = responseHTML.querySelector('.CreateAccountModal__section')
      let pendingConfirmationModalElement = responseHTML.querySelector('.js-PendingConfirmation__modal')
      let signInModalElement = responseHTML.querySelector('.SignIn__modal')

      if (signInModalElement) {
        this.element.replaceWith(signInModalElement)
      } else if (createAccountModalElement) {
        this.element.replaceWith(createAccountModalElement)
      } else if (pendingConfirmationModalElement) {
        this.element.replaceWith(pendingConfirmationModalElement)
      }
    }
  }
}
