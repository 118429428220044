import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    super.initialize()
    this.pageLoad()
  }

  pageLoad() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let {property_class, resorts, themed_rooms, amenities, entertainment, guests, bedrooms} = params
    // for each checkbox type set checked
    let checkbox_items = [
      property_class,
      resorts,
      themed_rooms,
      amenities,
      entertainment
    ]

    console.info(resorts);
    checkbox_items.filter(x => x !== null).forEach(v => {
      v.split(',').forEach(value => {
        document.querySelectorAll(`[value="${value}"]`).forEach(el => el.checked = true)
      })
    })

    // for each value based set value
    let value_based_keys = ['guests', 'bedrooms']
    value_based_keys.forEach(k => {
      let value = params[k]
      if (value) {
        document.querySelectorAll(`[name="${k}"]`).forEach(el => el.value = value)
      }
    })
  }

  submit() {
    const event = new CustomEvent("apply-filter", {
        bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
  clearAll() {
    const event = new CustomEvent("clear-filter", {
        bubbles: true,
    });
    this.element.dispatchEvent(event);
  }
}
