import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
  }


  closeErrorModal(event) {
    event.preventDefault();

    document.querySelector('.js-Error__modal').classList.add('Error__hide')
  }
}
