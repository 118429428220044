import { Controller } from "stimulus";
import { submitForm, loadRequestHtmlBy } from "init/ajax";

export default class extends Controller {
  connect() {
    this.menu = this.element.querySelector("nav");
    this.hamburger = this.element.querySelector(".hamburger");
    this.accountForm = this.element.querySelector(".account-login");
    this.contactBlock = this.element.querySelector(".contact-block");
    this.searchForm = this.element.querySelector(".search-form");
    this.searchBtn = this.element.querySelector(".svg--search");
  }

  initialize() {
    document.addEventListener('click', this.closeBlock)
    document.addEventListener('turbolinks:before-render', () => {
      delete window.olark
    });
    document.querySelector('.icon-chat').addEventListener('click', this.openChat);
    document.querySelector('.icon-chat-mobile').addEventListener('click', this.openChat);
    document.querySelector('.olark-toggle-btn').addEventListener('click', this.openChat);
  }

  menuClosed() {
    footer.style.zIndex = 0;
  }

  closeMenu() {
    this.element.classList.remove("open");
    this.menu.setAttribute("aria-hidden", "true");
    this.hamburger.setAttribute("aria-expanded", "false");
    document.body.style.overflow = "auto";
  }

  openMenu() {
    this.element.classList.add("open");
    this.menu.setAttribute("aria-hidden", "false");
    this.hamburger.setAttribute("aria-expanded", "true");
    document.body.style.overflow = "hidden";
  }

  openChat() {
    olark('api.box.expand');
    const stickyToggle = document.querySelector('.olark-toggle-btn');
    stickyToggle.classList.add('-hidden');

    olark('api.box.onShrink', function() {
      stickyToggle.classList.remove('-hidden');
    });
  }

  async showAddEmailModal(event) {
    event.preventDefault()

    let responseHTML = await loadRequestHtmlBy(event.target.href)
    let addEmailModalElement = responseHTML.querySelector('.AddEmail__modal')

    document.querySelector('body').insertAdjacentElement('afterbegin', addEmailModalElement);
  }

  toggle() {
    if (this.element.classList.contains("open")) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  closeSubmenu(e) {
    if (e) e.currentTarget.classList.remove("open");
    else {
      this.closest('.has-submenu').classList.remove("open");
    }
  }

  openSubmenu(e) {
    if (e.target.parentNode == e.currentTarget) e.preventDefault();
    e.currentTarget.classList.add("open");
  }

  toggleSubmenu(e) {
    e.preventDefault();
    if (e.target.parentNode == e.currentTarget) e.preventDefault();
    e.currentTarget.classList.toggle("open");
  }

  backToMenu() {
    this.closeSubmenu();
  }

  closeAccountForm(e) {
    this.accountForm.setAttribute("aria-hidden", "true");
    this.accountForm.classList.remove("open");
  }

  toggleAccountForm() {
    this.toggleAriaHidden(this.accountForm)
    this.accountForm.classList.toggle("open");
  }

  toggleAriaHidden(element) {
    let ariaHiddenValue = element.getAttribute('aria-hidden')
    let isHidden = ariaHiddenValue === "true" || ariaHiddenValue === null

    if (isHidden) {
      this.accountForm.setAttribute("aria-hidden", "false");
    } else {
      this.accountForm.setAttribute("aria-hidden", "true");
    }
  }

  toggleContact(e) {
    e.preventDefault();

    if (!e.target.closest('.contact-block') || !e.target.closest('.contact-block')) {
      return;
    } else {
      this.openContact();
    }
  }

  toggleBlock(event) {
    let target = event.currentTarget.dataset.target;
    let block = document.querySelector(`[data-block="${target}"]`)
    let openedBlock = document.querySelector('.toggled-block.open')
    if (openedBlock) {
      document.querySelector('.toggled-block.open').classList.remove('open')
    }
    block.classList.add('open')

  }

  closeBlock(event) {
    let openedBlock = document.querySelector('.toggled-block.open')
    if (event.target.closest('.js-toggle-block')){
      return
    }
    else if (!event.target.closest('.toggled-block') && openedBlock) {
      openedBlock.classList.remove('open')
    }
  }

  closeSearch() {
    this.searchForm.setAttribute("aria-hidden", "true");
    this.searchForm.classList.remove("open");
    this.searchBtn.classList.remove("active");
  }

  openSearch() {
    this.searchForm.setAttribute("aria-hidden", "false");
    this.searchForm.classList.add("open");
    this.searchBtn.classList.add("active");
  }


  toggleSearch () {
    if (this.searchForm.classList.contains("open")) {
      this.closeSearch();
    } else {
      this.openSearch();
    }
  }
}
