import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  closeModal(event) {
    event.preventDefault();

    document.querySelector('.js-PendingConfirmation__modal').classList.add('PendingConfirmation__hide')
  }
}
